import React from 'react'
import { types } from './Multihoraire'

function Type({ start, onChange }) {

    return (
        <select name="type" className="form-select" onChange={event => onChange(event.target.value)} value={start}>
            {types.map(type => (
                <option key={type.reference} value={type.reference}>{type.description}</option>
            ))}
        </select>
    )
}

export default Type