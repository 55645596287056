import React from 'react'
import TimeFrame from './TimeFrame'
import Button from 'react-bootstrap/Button'

function TimeFrames({ type, changeTimeFrames }) {

    const [timeFrames, setTimeFrames] = React.useState([{ index: 0 }])

    function addTimeFrame() {
        setTimeFrames([...timeFrames, { index: timeFrames.length > 0 ? Math.max(...timeFrames.map(i => i.index)) + 1 : 0 }])
    }

    function removeTimeFrame(item) {
        setTimeFrames(timeFrames.filter(i => i.index !== item))
    }

    function changeTimeFrame(timeFrame) {
        let newTimeFrames = []
        let found = false
        timeFrames.map(function (item) {
            if (item.index === timeFrame.index) {
                found = true
                return newTimeFrames.push(timeFrame)
            } else {
                return newTimeFrames.push(item)
            }
        })
        if (!found) {
            newTimeFrames.push(timeFrame)
        }
        setTimeFrames(newTimeFrames)
        return true
    }

    React.useEffect(() => {
        changeTimeFrames(timeFrames)
    }, [timeFrames/*, changeTimeFrames*/])

    return (
        <div className="timeFrames">
            {timeFrames.map(item => (
                <TimeFrame typeRef={type} key={item.index} cle={item.index} removeTimeFrame={removeTimeFrame} changeTimeFrame={changeTimeFrame}></TimeFrame>
            ))}
            <Button onClick={addTimeFrame} variant="primary">Ajouter une plage horaire</Button>
        </div>
    )
}

export default TimeFrames