import React from 'react'
import TimePeriod from './TimePeriod'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons'

import { listDays, convertDate, addIndexes } from './Multihoraire'

function Periode({ start, removePeriode, changePeriode, cle }) {

    const [timePeriods, setTimePeriods] = React.useState(() =>
        typeof start == 'object' ? addIndexes(start.timePeriods) : [{ index: 0 }]
    )
    const [dates, setDates] = React.useState(() =>
        typeof start == 'object' ? {
            debut: start.dateDebut,
            debutD: null,
            debutMin: convertDate(new Date()),
            debutMax: null,
            fin: start.dateFin,
            finD: null,
            finMin: convertDate(new Date()),
            finMax: null
        } : {
            debut: '',
            debutD: null,
            debutMin: convertDate(new Date()),
            debutMax: null,
            fin: '',
            finD: null,
            finMin: convertDate(new Date()),
            finMax: null
        }
    )
    const [allowedDays, setAllowedDays] = React.useState([])
    const [showDateFin, setShowDateFin] = React.useState(true)

    function addTimePeriod() {
        setTimePeriods([...timePeriods, { index: timePeriods.length > 0 ? Math.max(...timePeriods.map(i => i.index)) + 1 : 0 }])
    }

    function removeTimePeriod(item) {
        setTimePeriods(timePeriods.filter(i => i.index !== item))
    }

    function changeTimePeriod(changedPeriod) {
        let newTimePeriods = []
        let found = false;
        timePeriods.map(function (tp) {
            if (tp.index === changedPeriod.index) {
                found = true;
                return newTimePeriods.push(changedPeriod)
            } else return newTimePeriods.push(tp)
        })
        if (!found) newTimePeriods.push(changedPeriod)
        setTimePeriods(newTimePeriods)
    }

    function dateChange(type, event) {
        if (type === 'debut') {
            if (event.target.value.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) {
                let d = event.target.value.split('-')
                let newD = new Date(d[0], d[1] - 1, d[2])
                setDates({ ...dates, debutD: newD, debut: event.target.value, finMin: event.target.value })
            } else setDates({ ...dates, debutD: null, debut: '', finMin: '' })
        } else {
            if (event.target.value.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) {
                let d = event.target.value.split('-')
                let newD = new Date(d[0], d[1] - 1, d[2])
                setDates({ ...dates, finD: newD, fin: event.target.value })
            } else setDates({ ...dates, finD: null, fin: '' })
        }
    }

    function changeOneDay(event) {
        setShowDateFin(!event.target.checked)
    }

    React.useEffect(() => {
        let newDays = []

        // Date début seulement
        if (dates.debutD instanceof Date && (dates.finD === null || !showDateFin || dates.finD === dates.debutD)) {
            listDays.map(function (ad) {
                if (dates.debutD instanceof Date && ad.index === dates.debutD.getDay()) newDays.push(ad)
                return true
            })
        }
        // Période entre 2 dates
        else if (dates.debutD instanceof Date && dates.finD instanceof Date && dates.finD > dates.debutD) {
            var diff = Math.floor((dates.finD.getTime() - dates.debutD.getTime()) / (24 * 3600 * 1000));
            // + de 7 jours : on affiche tous les jours
            if (diff >= 7) newDays = [...listDays]
            // - de 7 jours : on affiche seulement les jours concernés
            else if (diff >= 2) {
                for (let i = 0; i <= diff; i++) {
                    let d = new Date()
                    d.setDate(dates.debutD.getDate() + i)
                    newDays = [...newDays, ...listDays.filter(i => i.index === d.getDay())]
                }
            }
        }

        setAllowedDays(newDays)
        if (dates.debut !== '') {
            let changedPeriod = {
                index: cle,
                dateDebut: dates.debut
            }
            if (dates.fin !== '') changedPeriod.dateFin = dates.fin
            changedPeriod.timePeriods = timePeriods
            changePeriode(changedPeriod)
        }
    }, [dates, timePeriods/*, changePeriode, cle, showDateFin*/])

    let dateFinClass = ''
    let dateFinFeedback = ''

    if (dates.fin !== '' && dates.debut > dates.fin) {
        dateFinClass = dates.debut > dates.fin ? 'is-invalid' : ''
        dateFinFeedback = <div className="invalid-feedback">Date de fin invalide</div>
    }

    return (
        <div className="periode row">
            <div className="col-3 row">
                <div className="col-4">
                    <FontAwesomeIcon icon={faCircleMinus} onClick={event => removePeriode(cle)} />
                    <div className="form-check">
                        <input type="checkbox" onChange={changeOneDay} className="form-check-input" />
                        <label className="form-check-label" htmlFor="changeOneDay">Un seul jour</label>
                    </div>
                </div>
                <div className="dates col-8">
                    <div className="form-floating mb-3">
                        <input id="du" type="date" className="form-control"
                            min={dates.debutMin}
                            max={dates.debutMax}
                            value={dates.debut}
                            onChange={(e) => dateChange('debut', e)} />
                        <label htmlFor="de">{showDateFin ? 'Du' : 'Le'}</label>
                    </div>
                    {showDateFin ?
                        <div className="form-floating mb-3">
                            <input id="au" type="date" className={`form-control ${dateFinClass}`}
                                min={dates.finMin}
                                max={dates.finMax}
                                value={dates.fin}
                                onChange={(e) => dateChange('fin', e)} />
                            <label htmlFor="TODO">Au</label>
                            {dateFinFeedback}
                        </div> : ''
                    }
                </div>
                <div className="form-floating">
                    <textarea className="form-control" placeholder="Autres précisions" id="TODO"></textarea>
                    <label className="floatingTextarea" htmlFor="TODO">Autres précisions</label>
                </div>
            </div>
            <div className="timePeriods col-9">
                {
                    dates.debut !== '' ? (
                        timePeriods.map(item => (
                            <TimePeriod start={item} key={item.index} cle={item.index} removeTimePeriod={removeTimePeriod} changeTimePeriod={changeTimePeriod} allowedDays={allowedDays} periodeCle={cle}></TimePeriod>
                        ))
                    ) : ''
                }
                {
                    dates.debut !== '' ? (
                        <Button variant="primary" onClick={addTimePeriod}>Ajouter des horaires</Button>
                    ) : ''
                }
            </div>
        </div>
    )
}

export default Periode