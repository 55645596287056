import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons'

import Type from './Type'
import WeekDays from './WeekDays'
import TimeFrames from './TimeFrames'

import { addIndexes } from './Multihoraire'

function TimePeriod({ start, cle, removeTimePeriod, changeTimePeriod, allowedDays, periodeCle }) {

    const [type, setType] = React.useState(() => typeof start == 'object' ? start.type : 'opening')
    const [weekDays, setWeekDays] = React.useState(() => typeof start == 'object' ? start.weekDays : [])
    const [timeFrames, setTimeFrames] = React.useState(() => typeof start == 'object' ? addIndexes(start.timeFrames) : [{ index: 0 }])

    console.log(type, weekDays, timeFrames)

    function changeTimeFrames(timeFrames) {
        setTimeFrames(timeFrames)
    }

    React.useEffect(() => {
        changeTimePeriod({
            index: cle,
            type: type,
            weekDays: weekDays,
            timeFrames: timeFrames
        })
    }, [type, weekDays, timeFrames])

    return (
        <div className="timePeriod row">
            <div className="col col-1">
                <FontAwesomeIcon icon={faCircleMinus} onClick={event => removeTimePeriod(cle)} />
            </div>
            <div className="col">
                <Type start={type} onChange={setType}></Type>
                <WeekDays allowedDays={allowedDays} changeWeekDays={setWeekDays} periodeCle={periodeCle} timePeriodCle={cle}></WeekDays>
            </div>
            <div className="col">
                <TimeFrames type={type} changeTimeFrames={changeTimeFrames}></TimeFrames>
            </div>
        </div>
    )
}

export default TimePeriod