import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const start = [
  {
    "dateDebut": "2022-09-19",
    "dateFin": "2022-09-22",
    "timePeriods": [
      {
        "type": "departure",
        "weekDays": [
          "WED",
          "THU"
        ],
        "timeFrames": [
          {
            "startTime": "03:33",
            "endTime": "15:33",
            "recurrence": "00:33"
          }
        ]
      }
    ]
  },
  {
    "dateDebut": "2022-10-18",
    "dateFin": "2022-10-20",
    "timePeriods": [
      {
        "type": "ceremony",
        "weekDays": [
          "MON"
        ],
        "timeFrames": [
          {
            "startTime": "03:34",
            "endTime": "11:38"
          }
        ]
      },
      {
        "type": "last_entry",
        "weekDays": [],
        "timeFrames": [
          {
            "startTime": "12:34"
          },
          {
            "startTime": "14:34"
          }
        ]
      }
    ]
  }
]

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App start={start} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
